<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <!-- <el-form-item label='省：'>
                        <el-select v-model="filters.provinceID" placeholder="请选择" @change='selProvince'>
                            <el-option
                                v-for="item in options"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID">
                                </el-option>
                            </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label='市：'>
                        <el-select v-model="filters.cityID" placeholder="请选择" @change='selCity'>
                            <el-option
                                v-for="item in options1"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID">
                                </el-option>
                            </el-select>
                    </el-form-item>
                    <el-form-item label='区/县：'>
                        <el-select v-model="filters.regionID" placeholder="请选择">
                            <el-option
                                v-for="item in options2"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID">
                                </el-option>
                            </el-select>
                    </el-form-item> -->
                    <el-form-item label="地区:">
                        <el-cascader :props="props" v-model="filters.selecAreaArr" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="名称：">
                        <el-input v-model='filters.name' placeholder="培训机构名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="审核状态：">
                        <el-select v-model="filters.selectType">
                            <el-option
                                v-for="item in questionswayArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
<!--                    <el-form-item label='隶属品牌:' prop='brandName'>-->
<!--                        <el-select v-model="filters.brandId" placeholder="请选择">-->
<!--                            <el-option-->
<!--                                v-for="item in brandTableList"-->
<!--                                :key="item.ID"-->
<!--                                :label="item.BrandName"-->
<!--                                :value="item.ID"-->
<!--                            >-->
<!--                            </el-option>-->
<!--                            <el-pagination-->
<!--                                layout="prev, pager, next"-->
<!--                                :total="brandListPage.DataCount"-->
<!--                                @current-change="brandListPageChange"-->
<!--                            >-->
<!--                            </el-pagination>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
                    <el-form-item label="联系人手机号:">
                        <el-input v-model='filters.userPhone' placeholder="手机号" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
        :data="listData"
        highlight-current-row
        v-loading="listLoading"
        border=""
        @current-change="selectCurrentRow"
        style="width: 95%;"
        >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <!-- <el-table-column prop="Province" align="center" label="省" width></el-table-column> -->
            <el-table-column prop="City" align="center" label="市" width></el-table-column>
            <el-table-column prop="Region" align="center" label="区县" width></el-table-column>
            <el-table-column prop="Name" align="center" label="培训机构名称" min-width="130"></el-table-column>
            <el-table-column prop="Brand" align="center" label="隶属品牌" min-width="100"></el-table-column>
            <el-table-column prop="ContactPerson" align="center" label="联系人" width=""></el-table-column>
            <el-table-column prop="ContactPersonPhoneNumber" align="center" label="联系人手机号" min-width="130"></el-table-column>
            <el-table-column prop="ScaleCount" align="center" label="办学规模(人)" min-width="120"></el-table-column>
            <el-table-column prop="RegisteredCapital" align="center" label="注册资本(万)" min-width="120"></el-table-column>
            <el-table-column prop="BusinessLicenseImgUrl" align="center" label="营业执照" min-width="100">
                <template scope="scope">
                    <img style="width:80px;height:80px;" :src="scope.row.BusinessLicenseImgUrl" alt="">
                </template>
            </el-table-column>
            <el-table-column prop="SchoolLicenceImgUrl" align="center" label="办学许可" min-width="100">
                <template scope="scope">
                    <img style="width:80px;height:80px;" :src="scope.row.SchoolLicenceImgUrl" alt="">
                </template>
            </el-table-column>
            <el-table-column prop="ClassRoomOneImgUrl" align="center" label="理论学习场景" min-width="115">
                <template scope="scope">
                    <img style="width:80px;height:80px;" :src="scope.row.ClassRoomOneImgUrl" alt="">
                </template>
            </el-table-column>
            <el-table-column prop="OperationOneImgUrl" align="center" label="实操学习场景" min-width="115">
                <template scope="scope">
                    <img style="width:80px;height:80px;" :src="scope.row.OperationOneImgUrl" alt="">
                </template>
            </el-table-column>
            <el-table-column prop="CreateTime" align="center" :formatter="formatCreateTime" label="注册时间" min-width="100"></el-table-column>
            <el-table-column prop="Status" align="center" label="审核状态" min-width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.Status == 0" type="warning">未审核</el-tag>
                    <el-tag v-if="scope.row.Status == 1" type="success">审核通过</el-tag>
                    <el-tag v-if="scope.row.Status == 2" type="danger">未通过</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsEnable" align="center" label="启用标识" min-width="100">
                <template scope="scope">
                    <el-tag v-if="scope.row.IsEnable" type="success">启用</el-tag>
                    <el-tag v-else type="warning">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="100">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 查看 -->
        <el-dialog
        title="查看"
        :visible.sync="viewsDetail"
        v-model="viewsDetail"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <!-- <el-form-item label="省份:">
                    <el-input v-model="viewsDetailData.Name" disabled placeholder="请输入培训机构名称"></el-input>
                </el-form-item>
                <el-form-item label="城市:">
                    <el-input v-model="viewsDetailData.Name" disabled placeholder="请输入培训机构名称"></el-input>
                </el-form-item>
                <el-form-item label="区/县:">
                    <el-input v-model="viewsDetailData.Name" disabled placeholder="请输入培训机构名称"></el-input>
                </el-form-item> -->
                <el-form-item label="培训机构名称:">
                    <div>{{viewsDetailData.Name}}</div>
                </el-form-item>
                <el-form-item label="联系人:">
                    <div>{{viewsDetailData.ContactPerson}}</div>
                </el-form-item>
                <el-form-item label="联系人手机号:">
                    <div>{{viewsDetailData.ContactPersonPhoneNumber}}</div>
                </el-form-item>
                <el-form-item label="所在地区:">
                    <div>{{viewsDetailData.Province}}<span v-if="viewsDetailData.City"> / </span>{{viewsDetailData.City}}<span v-if="viewsDetailData.Region"> / </span>{{viewsDetailData.Region}}</div>
                </el-form-item>
                <el-form-item label="详细地址:">
                    <div>{{viewsDetailData.Address}}</div>
                </el-form-item>
                <el-form-item label="办学规模(人):">
                    <div>{{viewsDetailData.ScaleCount}}</div>
                </el-form-item>
                <el-form-item label="注册资本(万):">
                    <div>{{viewsDetailData.RegisteredCapital}}</div>
                </el-form-item>
            </el-form>
            <el-form label-width="150px">
                    <el-form-item label="提供岗位:">
                        <el-tree
                            style="padding:10px 0;max-height:650px;overflow-y:auto;max-width:1000px;"
                            ref="meTree"
                            :data="dataTree"
                            :show-checkbox="true"
                            node-key="StationCode"
                            :setCheckedKeys="needViewsData1"
                            :props="defaultProps"
                            v-loading="listLoading2"
                        >
                            <span class="custom-tree-node" slot-scope="{ node, data }">
                                <span>{{ node.label }}</span>
                                <span>
                                    <el-checkbox-group v-model="assignBtns" :disabled="true" class="ediColor">
                                        <el-checkbox v-for="btn in data.Buttons" :key="btn.Id"
                                            :label="btn.Id.toString()">
                                            {{btn.Name}}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </span>
                            </span>
                        </el-tree>
                    </el-form-item>
                </el-form>
            <el-form label-width="150px" :inline='true'>
                <el-form-item label="营业执照:">
                    <img :src="viewsDetailData.BusinessLicenseImgUrl" @click="viewsImagesFun(viewsDetailData.BusinessLicenseImgUrl)" style="width:140px;height:140px;cursor: pointer;" title="点击查看大图">
                </el-form-item>
                <el-form-item label="办学许可证:">
                    <img :src="viewsDetailData.SchoolLicenceImgUrl" @click="viewsImagesFun(viewsDetailData.SchoolLicenceImgUrl)" style="width:140px;height:140px;cursor: pointer;" title="点击查看大图">
                </el-form-item>
            </el-form>
            <el-form label-width="150px" :inline='true'>
                <el-form-item label="前台外观照:">
                    <img :src="viewsDetailData.ReceptionOneImgUrl" @click="viewsImagesFun(viewsDetailData.ReceptionOneImgUrl)" style="width:140px;height:140px;cursor: pointer;" title="点击查看大图">
                </el-form-item>
                <el-form-item label=" ">
                    <img :src="viewsDetailData.ReceptionTwoImgUrl" @click="viewsImagesFun(viewsDetailData.ReceptionTwoImgUrl)" style="width:140px;height:140px;cursor: pointer;" title="点击查看大图">
                </el-form-item>
            </el-form>
            <el-form label-width="150px" :inline='true'>
                <el-form-item label="理论学习场景照:">
                    <img :src="viewsDetailData.ClassRoomOneImgUrl" @click="viewsImagesFun(viewsDetailData.ClassRoomOneImgUrl)" style="width:140px;height:140px;cursor: pointer;" title="点击查看大图">
                </el-form-item>
                <el-form-item label=" ">
                    <img :src="viewsDetailData.ClassRoomTwoImgUrl" @click="viewsImagesFun(viewsDetailData.ClassRoomTwoImgUrl)" style="width:140px;height:140px;cursor: pointer;" title="点击查看大图">
                </el-form-item>
            </el-form>
            <el-form label-width="150px" :inline='true'>
                <el-form-item label="实操学习场景照:">
                    <img :src="viewsDetailData.OperationOneImgUrl" @click="viewsImagesFun(viewsDetailData.OperationOneImgUrl)" style="width:140px;height:140px;cursor: pointer;" title="点击查看大图">
                </el-form-item>
                <el-form-item label=" ">
                    <img :src="viewsDetailData.OperationTwoImgUrl" @click="viewsImagesFun(viewsDetailData.OperationTwoImgUrl)" style="width:140px;height:140px;cursor: pointer;" title="点击查看大图">
                </el-form-item>
            </el-form>
            <!-- <el-form label-width="150px" :inline='true'>
                <el-form-item label="学习入口二维码:">
                    <img :src="viewsDetailData.LessonQRCodeImgUrl" style="width:140px;height:140px;" alt="">
                </el-form-item>
            </el-form> -->
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="viewsDetail = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 关联品牌 -->
        <el-dialog
            title="关联品牌"
            :visible.sync="relationBrandDialog"
            v-model="relationBrandDialog"
            :close-on-click-modal="false"
            min-height="300"
        >
            <el-form :model='relationBrandFrom' :rules='relationBrandRules' ref='relationBrandRef' label-width='90px'>
                <el-form-item label='隶属品牌:'>
                    <el-select v-model="relationBrandFrom.brandName" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in brandTableList"
                            :key="item.ID"
                            :label="item.BrandName"
                            :value="item.ID"
                        >
                        </el-option>
                        <el-pagination
                            layout="prev, pager, next"
                            :total="brandListPage.DataCount"
                            @current-change="brandListPageChange"
                        >
                        </el-pagination>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="relationBrandDialog = false">取 消</el-button>
                <el-button type="primary" @click="relationBrandSubmit" :loading="relationBrandLoading">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 查看大图 -->
        <el-dialog
            title="查看大图"
            :visible.sync="viewsImagesDialog"
            v-model="viewsImagesDialog"
            :close-on-click-modal="false"
            width="65%"
        >
            <div>
                <img :src="viewsImagesUrl" style="max-width: 100%;max-height: 100%;" alt="">
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="viewsImagesDialog = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getAgencyInfoListPage,approveAgencyInfo,deleteAgencyInfo,getDataDictionaryList,getAreaDictionaryList,getBrandListPage,relationBrand } from '@/api/api'
import util from "../../../util/date";
import Qs from 'qs'
export default {
    components: {Toolbar},
    data(){
        return {
            // 省、市、县
            options:[],
            options1:[],
            options2:[],
            // 父ID
            parentProvinceID: 1919,
            parentCityID:null,
            filters: {
                provinceID:null,
                cityID:null,
                regionID:null,
                selecAreaArr: [],
                name: '',
                userPhone: '',
                selectType: '222',
                brandId: '',
            },
            buttonList: [],
            listData: [],
            listLoading: false,
            questionswayArr: [
                {value:"222",name:'选择状态'},
                {value:0,name:'未审核'},
                {value:1,name:'审核通过'},
                {value:2,name:'未通过'},
            ],
            currentData: '',
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            viewsDetail: false,
            viewsDetailData: '',
            regionArr: [
                {code:0,name:'北京'},
                {code:1,name:'上海'},
                {code:2,name:'广州'},
                {code:3,name:'深圳'},
                {code:4,name:'杭州'},
            ],
            dataTree: [],
            assignBtns: [],
            needViewsData1: [],
            needViewsData2: [],
            defaultProps: {
                label: "StationName",
                value:'Id'
            },
            listLoading2:false,
            isFirst: 1,
            // 关联品牌
            relationBrandDialog: false,
            relationBrandLoading: false,
            brandTableList: [],
            brandListPage: {
                DataCount: 10,
                pageIndex: 1,
                pageSize: 10,
            },
            relationBrandFrom: {
                brandName: '',
            },
            relationBrandRules: {
                brandName:[{required:true,message:'请选择隶属单位',trigger:'blur'}],
            },
            // 查看大图
            viewsImagesDialog: false,
            viewsImagesUrl: "",
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: 1919,
                            level: 1,
                        }
                        level = false
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response.reverse()
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
        }
    },
    methods: {
        // 选中省、市、区当前行
        selProvince(value){
            this.parentProvinceID = value
            var params = {
                level:1,
                parentId:this.parentProvinceID
            }
            this.getCity(params)
        },
        selCity(value){
            this.parentCityID = value
            var params = {
                parentId:this.parentCityID
            }
            this.getArea(params)
        },
        // 获取省、市、区的数据
        getProvince(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.options = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getCity(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.options1 = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getArea(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.options2 = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getAgencyInfoListPage() {
            this.getListData();
        },
        // 通过审核
        handleApprove() {
            if(this.currentData){
                this.$confirm('确定通过审核？',"提示",{
                }).then(() => {
                    var params = {
                        Id: this.currentData.Id
                    }
                    approveAgencyInfo(params).then((res) => {
                        var result = res.data
                        if(result.Success){
                            this.$message({
                                message: result.Message,
                                type: 'success'
                            });
                            this.getListData();
                        }else{
                            this.$message({
                                message: result.Message,
                                type: 'error'
                            });
                        }
                    })
                })
                .catch(() => {

                })
            }else{
                this.$message({
                    message:'请至少选择一项！',
                    type:'warning'
                })
            }
        },
        // 切换分页
        brandListPageChange(e) {
            this.brandListPage.pageIndex = e
            // this.getBrandListPage()
        },
        // 获取品牌分页列表
        getBrandListPage() {
             let  params = {
                pageIndex: this.brandListPage.pageIndex,
                pageSize: 10,
            }
            getBrandListPage(params).then((res) => {
                let result = res.data
                if(result.Success) {
                    this.brandTableList = result.Response.Data
                    this.brandListPage.DataCount = result.Response.DataCount
                }
            })
        },
        // 关联品牌
        relationBrand() {
            if(this.currentData){
                this.relationBrandDialog = true
                this.relationBrandLoading = false
                if(this.currentData.BrandID) {
                    this.relationBrandFrom.brandName = this.currentData.BrandID
                } else {
                    this.relationBrandFrom.brandName = ''
                }
            }else{
                this.$message({
                    message:'请至少选择一项！',
                    type:'warning'
                })
            }
        },
        relationBrandSubmit() {
            this.$refs['relationBrandRef'].validate(valid => {
                if(valid){
                    var params = {
                        BrandID: this.relationBrandFrom.brandName,
                        AgencyID: this.currentData.ID
                    }
                    if(this.currentData.BrandID && !this.relationBrandFrom.brandName) {
                        params.Status = 0
                        params.BrandID = this.currentData.BrandID
                    }
                    this.relationBrandLoading = true
                    relationBrand(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.relationBrandDialog = false
                            this.relationBrandLoading = false
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                }
            })
        },
        // 删除
        handleDel() {
            if(this.currentData){
                this.$confirm('确定关停？',"提示",{
                }).then(() => {
                    var params = {
                        Id: this.currentData.Id
                    }
                    deleteAgencyInfo(params).then((res) => {
                        var result = res.data
                        if(result.Success){
                            this.$message({
                                message: result.Message,
                                type: 'success'
                            });
                            this.getListData();
                        }else{
                            this.$message({
                                message: result.Message,
                                type: 'error'
                            });
                        }
                    })
                })
                .catch(() => {

                })
            }else{
                this.$message({
                    message:'请选择要关停一项！',
                    type:'warning'
                })
            }
        },
        // 获取分页列表
        getListData() {
            var _this = this
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.filters.name){
                params.name = this.filters.name
            }
            if(this.filters.userPhone){
                params.contactPersonPhoneNumber = this.filters.userPhone
            }
            if(this.filters.selectType != '222'){
                params.status = this.filters.selectType
            }
            if(this.filters.selecAreaArr.length > 0) {
                params.cityID = this.filters.selecAreaArr[0]
            }
            if(this.filters.selecAreaArr.length > 1) {
                params.regionID = this.filters.selecAreaArr[1]
            }
            if(this.filters.brandId){
                params.brandID = this.filters.brandId
            }
            this.listLoading = true;
            getAgencyInfoListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    _this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.listData = result.Response.Data
                }
            })
        },
        // 获取岗位信息
        getDictionaryList(val) {
            this.listLoading2 = true
            // var params = {
            //     dataType: 1,
            // }
            // getDataDictionaryList(params).then((res) => {
            //     var result = res.data
            //     if(result.Success){
                  val.ProgramList.forEach(item => {
                        item.disabled = true
                        item.Buttons = [{
                            Id:item.StationCode+'-'+1,
                            IsHide:false,
                            Name:'理论线上学习'
                        },{
                            Id:item.StationCode+'-'+2,
                            IsHide:false,
                            Name:'理论线下面授'
                        },{
                            Id:item.StationCode+'-'+3,
                            IsHide:false,
                            Name:'实操面授'
                        }]
                    });
                    // this.dataTree = result.Response
                  console.log(val,'val1111')
                    this.dataTree = val.ProgramList
	        console.log(this.needViewsData1,'data1111')
	        console.log(this.$refs.meTree,'tree')
                   if(this.$refs.meTree){
	                   this.$refs.meTree.setCheckedKeys(this.needViewsData1);
                   }
                    this.assignBtns = this.needViewsData2
                    console.log(this.assignBtns,'assignBtns')
                    this.listLoading2 = false;
            //     }else{
            //         this.listLoading2 = false;
            //     }
            // })
        },
        handleClick(val) {
            
            this.viewsDetailData = val
            
            this.needViewsData1 = []
            this.needViewsData2 = []
            if (val.ProgramList&&val.ProgramList.length>0){
	            val.ProgramList.forEach(item => {
		            this.needViewsData1.push(item.StationCode)
		            if(item.Online){
			            let a = +item.StationCode+'-'+1
			            this.needViewsData2.push(a.toString())
		            }
		            if(item.OffLine){
			            let a = +item.StationCode+'-'+2
			            this.needViewsData2.push(a.toString())
		            }
		            if(item.HandsOn){
			            let a = +item.StationCode+'-'+3
			            this.needViewsData2.push(a.toString())
		            }
	            })
	            this.getDictionaryList(val);
            }
	        
	        this.viewsDetail = true
        },
        // 查看大图
        viewsImagesFun(imageVal) {
            this.viewsImagesDialog = true
            this.viewsImagesUrl = imageVal
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.currentData = val
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },
        handleSizeChange(val){
            this.pages.pageSize = val
            this.getListData();
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    mounted() {
        this.getListData();
        // this.getBrandListPage();
        // var params = {
        //     level:0
        // }
        // this.getProvince(params)
        var params = {
            parentId: 1919,
            level: 1,
        }
        // this.getCity(params)
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
    .ediColor>>>.el-checkbox__input.is-disabled+span.el-checkbox__label{
        color #606266!important;
    }
</style>
